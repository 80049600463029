import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_menus.png'

const Contact = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      A la Carte
    </div>
    <div
      style={{
        width: 500,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
        position: 'absolute',
        top: 83,
        left: 250,
      }}
    >
      <p style={{ height: 500, overflowY: 'scroll' }}>
        <b>SMOKED CHICKEN THIGH 1lb $11.00</b>
        <br />
        Per 4oz. Cal 130 Fat 7.0 Carb 0.0 Protein 18.0
        <br />
        <br />
        <b>CHIPOTLE CHICKEN BREAST 1lb 11.00</b>
        <br />
        Per 4oz Cal 103 Fat 4.0 Carb .08 Protein 18.9
        <br />
        <br />
        <b>ROASTED PORK SIRLOIN 1 lb. $11.00</b>
        <br />
        Per 4oz Cal 136 Fat 2.9 Carb 0.0 Protein 25.6
        <br />
        <br />
        <b>SEASONED GROUND TURKEY 1 lb $11.00</b>
        <br />
        Per 4oz Cal 150 Fat 8.0 Carb 0.0 Protein 22.0
        <br />
        <br />
        <b>
          GROUND TURKEY ITALIAN MEATBALLS IN MARINARA AND PARMESAN 8 MB $11.00
        </b>
        <br />
        Per 2 MB Cal 211 Fat 8.5 Carb 10.2 Protein 24.9
        <br />
        <br />
        <b>CHOCOLATE BANANA MUFFINS 1/2 dozen $15.00</b>
        <br />1 Muffin Cal 101 Fat 4.6 Carb 12.1 Protein 2.9
        <br />
        <br />
        <b>
          Cranberry Almond Energy Bites (gluten free oatmeal, slivered almonds,
          shredded coconut, ground flaxseed, chia seeds, honey, almond butter,
          dried cranberries, plant protein powder)
        </b>
        <br />
        Per 1 Ball Cal 115 Fat 6.2 Carb 12.8 Protein 2.9
        <br />
        8 Energy Balls for 10$
        <br />
        <br />
        <b>
          Chocolate Peanut Butter Protein Balls(pb2,chocolate protein,flax
          meal,coconut,vanilla)
        </b>
        <br />
        Per 1 Ball Cal 71 Fat 4.1 Carb 3.1 Protein 6.0
        <br />
        8 Protein Ball 10.00$
        <br />
        <br />
        <b>Churro or Vanilla Protein Donuts</b>
        <br />
        Per 1 donut Cal 84 Fat 4.9 Carb 5.7 Protein 4.5
        <br />
        10$ for 8 Donuts
        <br />
        <br />
        <b>Roasted Salsa</b>
        <br />
        (habanero,jalapeno,garlic,onion,tomato,salt)
        <br />
        16 oz 6$
        <br />
        32 oz 10$
        <br />
        <br />
        <u>DF Dairy Free</u>
        <br />
        <u>All meals are Gluten Free</u>
        <br />
        <br />
        <u>Meals made in facility that processes nuts and dairy</u>
      </p>
    </div>
  </Layout>
)

export default Contact
